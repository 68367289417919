import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

import { INITIAL_REDIRECT_STATE } from '../auth.constants';

@Injectable()
export class AuthGuard {
  constructor(private readonly router: Router, private readonly oauthService: OAuthService) {}

  canActivate() {
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      return true;
    } else {
      localStorage.setItem(INITIAL_REDIRECT_STATE, window.location.pathname + window.location.search);
      this.router.navigate(['/login']);
      return false;
    }
  }
}
