import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.issuer,

  logoutUrl: environment.logoutUrl,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/auth/callback',

  silentRefreshRedirectUri: window.location.origin + '/silent-refresh',

  postLogoutRedirectUri: window.location.origin,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.clientId,

  responseType: 'code',

  disableAtHashCheck: true,

  clearHashAfterLogin: false,

  preserveRequestedRoute: true,

  scope: environment.scope,

  showDebugInformation: true,

  strictDiscoveryDocumentValidation: false,

  skipIssuerCheck: true,

  // refreshes auth token only once
  timeoutFactor: 0.9
};
