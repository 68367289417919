import { AfterViewInit, Component, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { CxSnackbarService } from "@bbraun/cortex/snackbar";
import { UnsubscriberService } from '@bbraun/helpex/src/lib/unsubscribe';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';

import { authCodeFlowConfig } from './modules/auth/auth-code-flow.config';
import { InstanaService } from './modules/shared/services/instana/instana.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {
  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router,
    private readonly instanaService: InstanaService,
    private readonly cxSnackbarService: CxSnackbarService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly unsubscriberService: UnsubscriberService
  ) {
    this.configure();
    this.cxSnackbarService.setViewContainerRef(this.viewContainerRef);
  }

  ngAfterViewInit() {
    this.instanaService.loadInstanaMonitoringScript();
    this.instanaService.loadInstanaMonitoringRouterEvents();
  }

  private configure() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(
      () => {
        this.router.initialNavigation();
        this.setupAutomaticLogoutInCaseOfTokenExpiry();
      }
    );
  }

  private setupAutomaticLogoutInCaseOfTokenExpiry(): void {
    if (!this.oauthService.events) {
      return;
    }

    this.oauthService.events
      .pipe(this.unsubscriberService.takeUntilDestroy)
      .subscribe((event: OAuthEvent) => {
        if (event.type === 'token_expires') {
          this.router.navigateByUrl('login');
        }
      });
  }
}
