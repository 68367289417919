import { createAction, props } from '@ngrx/store';

import { UserInfo } from '../../modules/shared/interfaces/user/user-info.interface';

export const GET_USER_RIGHTS = '[APP] GET_USER_RIGHTS';
export const GET_USER_RIGHTS_SUCCESS = '[APP] GET_USER_RIGHTS_SUCCESS';
export const IS_USER_INFO_AVAILABLE = '[APP] HAS_USER_INFO';
export const CLEANUP_PACKAGES = '[APP] CLEAN_PACKAGES';

export const GetUserInfoAction = createAction(GET_USER_RIGHTS);

export const GetUserInfoSuccessAction = createAction(GET_USER_RIGHTS_SUCCESS, props<{ payload: UserInfo }>());

export const IsUserInfoAvailable = createAction(IS_USER_INFO_AVAILABLE);

export const CleanupPackagesAction = createAction(CLEANUP_PACKAGES);
