import { OAuthModuleConfig } from 'angular-oauth2-oidc';

import { environment } from '../../../environments/environment';

export const oAuthModuleConfig: OAuthModuleConfig = {
  resourceServer : {
    allowedUrls: [
      environment.apiUrl
    ],
    sendAccessToken: true
  }
};
