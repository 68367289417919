export const environment = {
  apiUrl: window['env']['apiUrl'] || 'https://portal.iomtdev.bbraun.io/api/dfm',
  adminUrl: window['env']['adminUrl'] || 'https://portal.iomtdev.bbraun.io/admin/',
  sdUrl: window['env']['sdUrl'] || 'https://sd.iomtdev.bbraun.io',
  pmUrl: window['env']['pmUrl'] || 'https://pm.iomtdev.bbraun.io',
  femURL: window['env']['femURL'] || 'https://fem.dev.bbraun.io',
  production: false,
  loginUrl: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/oauth2/v2.0/authorize',
  logoutUrl: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/oauth2/v2.0/logout',
  issuer: 'https://login.microsoftonline.com/15d1bef2-0a6a-46f9-be4c-023279325e51/v2.0',
  clientId: '54560737-d054-4c25-99f5-bf06edf7c7ac',
  scope: 'openid offline_access profile email',
  trackingUi: {
    enable: true,
    reportingUrl: 'https://eum-green-saas.instana.io',
  },
  instanaApiKey: window['env']['instanaApiKey'] || '',
  azureMapKey: window['env']['azureMapKey'] || '',
};
