import { AfterViewChecked, Component } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';

import { INITIAL_REDIRECT_STATE } from '../auth.constants';

@Component({
  selector: 'app-redirect-endpoint',
  templateUrl: './redirect-endpoint.component.html'
})
export class RedirectEndpointComponent implements AfterViewChecked {

  wasNotCalled = true;

  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router
  ) {
  }

  ngAfterViewChecked() {
    if (this.oauthService.getIdToken() && this.oauthService.getAccessToken() && this.wasNotCalled) {
      const route = localStorage.getItem(INITIAL_REDIRECT_STATE) ? localStorage.getItem(INITIAL_REDIRECT_STATE) : '/';

      this.wasNotCalled = false;
      this.router.navigateByUrl(route);
    }
  }
}
