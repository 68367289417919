import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';

import { authCodeFlowConfig } from './auth-code-flow.config';
import { oAuthModuleConfig } from './o-auth-module.config';
import { RedirectEndpointComponent } from './redirect-endpoint/redirect-endpoint.component';

@NgModule({
  imports: [
    OAuthModule.forRoot()
  ],
  declarations: [
    RedirectEndpointComponent
  ],
  exports: [
    RedirectEndpointComponent
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: authCodeFlowConfig },
        { provide: OAuthModuleConfig, useValue: oAuthModuleConfig}
      ]
    };
  }
  constructor (@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}

