import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CxMaterialConfigProviders } from "@bbraun/cortex";
import { CxDialogModule, CxDialogService } from "@bbraun/cortex/dialog";
import { HttpService } from "@bbraun/helpex/src/lib/http";
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './modules/auth/auth.module';
import { AuthGuard } from './modules/auth/guard/auth.guard';
import { SplashScreenModule } from './modules/shared/components/splash-screen/splash-screen.module';
import { HttpErrorInterceptor } from './modules/shared/interceptors/http-error.interceptor';
import { AuthEffects } from './store/auth/auth.effects';
import { AuthReducer } from './store/auth/auth.reducer';
import { CUSTOM_SNACKBAR_CONFIG } from "./modules/shared/constants/snackbar-config/snackbar-config.constants";
import { SNACKBAR_CONFIG } from "@bbraun/cortex/snackbar";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    HttpClientModule,
    CxDialogModule,
    MatFormFieldModule,
    MatInputModule,
    AuthModule.forRoot(),
    StoreModule.forRoot({auth: AuthReducer}),
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    })
  ],
  providers: [
    AuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    { provide: SNACKBAR_CONFIG, useValue: CUSTOM_SNACKBAR_CONFIG },
    CxDialogService,
    CxMaterialConfigProviders,
    HttpService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
