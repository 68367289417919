import { Injectable } from '@angular/core';

import { HttpService } from "@bbraun/helpex/src/lib/http";
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { UserInfo } from '../../interfaces/user/user-info.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private readonly httpService: HttpService) { }

  getUserInfo(): Observable<UserInfo> {
    return this.httpService
      .request(`v1/user-info`)
      .get(environment.apiUrl);
  }
}
