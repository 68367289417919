import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CxDialogService } from "@bbraun/cortex/dialog";
import { HTTP_STATUS_CODES } from '@bbraun/cortex/shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly dialogService: CxDialogService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (ev: HttpEvent<any>) => {
          return ev;
        },
        (err: any) => {
          if (err.status === HTTP_STATUS_CODES.CONFLICT) {
            const title = 'Refresh page';
            const message = 'The device twin properties have already been changed by another user.';
            const button = 'refresh';

            this.dialogService.openConfirmDialog(title, message, button).subscribe(() => window.location.reload());
          } else if (err instanceof HttpErrorResponse) {
            this.router.navigate(['error'], {
              queryParams: {
                errorStatusCode: err.status,
                errorCodeIoMT: err?.error?.errorCode
              }
            });
          }
        }
      )
    );
  }
}
