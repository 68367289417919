import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './modules/auth/guard/auth.guard';
import { LoginComponent } from './modules/auth/login/login.component';
import { RedirectEndpointComponent } from './modules/auth/redirect-endpoint/redirect-endpoint.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/overview/overview.module').then((m) => m.OverviewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'device-details/:uuid',
    loadChildren: () => import('./modules/device-details/device-details.module').then((m) => m.DeviceDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'device-twin',
    loadChildren: () => import('./modules/device-twin/device-twin.module').then((m) => m.DeviceTwinModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer-details/:id',
    loadChildren: () => import('./modules/customer-details/customer-details.module').then((m) => m.CustomerDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'application-details/:uuid',
    loadChildren: () => import('./modules/application-details/application-details.module').then((m) => m.ApplicationDetailsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'application-device-twin',
    loadChildren: () => import('./modules/application-device-twin/application-device-twin.module').then((m) => m.ApplicationDeviceTwinModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'list-view',
    loadChildren: () => import('./modules/home/home-list-view/home-list-view.module').then((m) => m.HomeListViewModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/shared/components/error-page/error-page.module').then((m) => m.ErrorPageModule),
    canActivate: [AuthGuard]
  },
  {path: 'auth/callback', component: RedirectEndpointComponent},
  {path: 'login', component: LoginComponent},
  {path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
